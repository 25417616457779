import {makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import {PromotionalWidgetType} from "data/enums";

export interface IMostSelectedGolfers {
	playerId: number;
	owned: number;
}

export interface IMostSelectedGolfersAsCaptains {
	playerId: number;
	selectedAsCaptainPercentage: number;
}

export interface IGolfersWithHighestProjPts {
	playerId: number;
	projPts: number | null;
}

export interface IWidgetPlayers {
	mostSelectedGolfers: IMostSelectedGolfers[];
	mostSelectedGolfersAsCaptains: IMostSelectedGolfersAsCaptains[];
	golfersWithHighestProjPts: IGolfersWithHighestProjPts[];
	stage: PromotionalWidgetType;
}

export interface IPromotionalWidgetStore {
	get widgetData(): IWidgetPlayers | null;

	fetchPlayers(tournamentId: number): Promise<void>;
}

@injectable()
export class PromotionalWidgetStore implements IPromotionalWidgetStore {
	@observable _players: IWidgetPlayers | null = null;

	get widgetData() {
		return this._players;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	async fetchPlayers(tournamentId: number) {
		const {data} = await this._jsonProvider.widgetPlayers(tournamentId);

		runInAction(() => {
			this._players = data;
		});
	}
}
