import {makeAutoObservable, observable, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {ModalType, OrderBy, RequestState, SortOrder} from "data/enums";
import type {
	ILadderFilter,
	ILeaderboardItem,
	ILeaderboardsStore,
} from "data/stores/leaderboards/leaderboards.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import type {ISegment, ISegmentsStore} from "data/stores/segments/segments.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {includes, uniq} from "lodash";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {TrackAnalytics} from "data/utils/analytic_service";
import {ChangeEvent} from "react";

interface IProps {
	leagueId: number;
}

export interface ILeagueTableController extends ViewController<IProps> {
	i18n: ILocalizationStore;
	get isLoading(): boolean;
	get isLoadingMore(): boolean;
	get rankingsList(): ILeaderboardItem[];
	get nextPage(): boolean;
	get userItem(): ILeaderboardItem | null;
	get tournaments(): ITournament[];
	get ladderFilter(): ILadderFilter;
	get selectedTournament(): ITournament | undefined;
	get currentSortBy(): string;
	get currentOrder(): SortOrder;
	get segments(): ISegment[];

	loadMoreUsers: () => void;
	onTournamentChange: (tournamentId: number) => void;
	onSegmentChange: (segmentId: number) => void;
	onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onSortByStat: (stat: OrderBy) => void;
	showCompareModal: (userId: number) => void;
	onClearSearch: () => void;
}

@injectable()
export class LeagueTableController implements ILeagueTableController {
	@observable private _page = 1;
	@observable private _limit = 10;
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateLoadMore = RequestState.IDLE;
	@observable private _leagueId: number = 0;
	@observable _currentSortBy: string = OrderBy.TotalPoints;
	@observable _currentOrder = SortOrder.ASC;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardsStore) readonly _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore,
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.LeaguesStore) public _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get isLoadingMore(): boolean {
		return this._requestStateLoadMore === RequestState.PENDING;
	}

	get rankingsList(): ILeaderboardItem[] {
		const rankings = this._leaderboardsStore.leaderboard.rankings;
		return rankings.filter(
			(item, index) => index !== 0 || item.userId !== this.userItem?.userId
		);
	}

	get nextPage(): boolean {
		return this._leaderboardsStore.leaderboard.nextPage;
	}

	get userItem(): ILeaderboardItem | null {
		return this._leaderboardsStore.leaderboard.user;
	}

	get tournaments(): ITournament[] {
		const selectedTournaments = this._tournamentsStore.list.filter((t) =>
			includes(this._leaguesStore.tournamentsIds, t.id)
		);

		if (this.ladderFilter.selectedSegmentId) {
			return selectedTournaments.filter(
				({seasonSegmentId}) => seasonSegmentId === this.ladderFilter.selectedSegmentId
			);
		}

		return selectedTournaments;
	}

	get ladderFilter() {
		return this._leaderboardsStore.ladderFilter;
	}

	get selectedTournament() {
		return this.tournaments.find((it) => it.id === this.ladderFilter.selectedTournamentId);
	}

	get currentSortBy(): string {
		return this._currentSortBy;
	}

	get currentOrder(): SortOrder {
		return this._currentOrder;
	}

	get segments() {
		const selectedTournaments = this._leaguesStore.tournamentsIds.length
			? this._tournamentsStore.list.filter((tournament) =>
					includes(this._leaguesStore.tournamentsIds, tournament.id)
				)
			: this._tournamentsStore.list;
		const selectedSegmentsIds = uniq(selectedTournaments.map((it) => it.seasonSegmentId));

		return selectedSegmentsIds.length
			? this._segmentsStore.list.filter((it) => includes(selectedSegmentsIds, it.id))
			: this._segmentsStore.list;
	}

	private get requestParams() {
		return {
			leagueId: this._leagueId || 0,
			page: this._page,
			limit: this._limit,
			orderBy: this._currentSortBy,
			orderDirection: this._currentOrder,
			tournamentId:
				this.ladderFilter.selectedTournamentId === 0
					? undefined
					: this.ladderFilter.selectedTournamentId,
			seasonSegmentId:
				this.ladderFilter.selectedSegmentId === 0
					? undefined
					: this.ladderFilter.selectedSegmentId,
			search: this.ladderFilter.search || undefined,
		};
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	private onLoadPageTrack = () => {
		TrackAnalytics.trackOnLoad({
			LeagueName: this.league?.name,
		});
	};

	init({leagueId}: IProps) {
		this._leagueId = leagueId;
		this.onLoadPageTrack();

		const tournament = this._tournamentsStore.leaderboardTournament;
		const leagueHasLeaderboardTournament = this.tournaments.some(
			({id}) => id === tournament?.id
		);

		if (tournament && leagueHasLeaderboardTournament) {
			this._leaderboardsStore.setSelectedTournamentId(tournament.id);

			const futureTournament = this._tournamentsStore.scheduleTournaments.find(
				({id}) => id === tournament.id
			);

			this._currentSortBy = futureTournament ? OrderBy.TotalPoints : OrderBy.TournamentPoints;
		}

		void this._fetchLeaderboard();
	}

	private onError = (e: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(e),
		});
	};

	private _fetchLeaderboard = async () => {
		this._page = 1;

		try {
			this._requestState = RequestState.PENDING;

			await this.requestLeaderboard();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	};

	loadMoreUsers = async () => {
		++this._page;

		try {
			this._requestStateLoadMore = RequestState.PENDING;

			await this.requestLeaderboardMore();

			runInAction(() => {
				this._requestStateLoadMore = RequestState.SUCCESS;
			});
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	};

	private async requestLeaderboard() {
		await this._leaderboardsStore.fetchLeagueLeaderboard(this.requestParams);
	}

	private async requestLeaderboardMore() {
		await this._leaderboardsStore.fetchLeagueLeaderboardMore(this.requestParams);
	}

	private getTournamentAnalyticsValue = (tournamentId: number) => {
		const tournament = this._tournamentsStore.list.find(({id}) => id === tournamentId);
		if (!tournament) return "overall";

		return tournament.name.toLowerCase().replaceAll(" ", "_");
	};

	onTournamentChange = (tournamentId: number) => {
		TrackAnalytics.trackOnAction({
			event: "Filter Applied",
			SelectionName: "tournament",
			InitialValue: this.getTournamentAnalyticsValue(this.ladderFilter.selectedTournamentId),
			EndValue: this.getTournamentAnalyticsValue(tournamentId),
			PageArea: "primary_controls",
			LeagueName: this.league?.name,
		});

		this._leaderboardsStore.setSelectedTournamentId(tournamentId);

		const futureTournament = this._tournamentsStore.scheduleTournaments.find(
			({id}) => id === tournamentId
		);

		this._currentOrder = SortOrder.ASC;
		this._currentSortBy =
			futureTournament || !tournamentId ? OrderBy.TotalPoints : OrderBy.TournamentPoints;

		void this._fetchLeaderboard();
	};

	private getSegmentAnalyticsValue = (segmentId: number) => {
		const segment = this._segmentsStore.list.find(({id}) => id === segmentId);
		if (!segment) return "overall";

		const segmentName = segment.name.toLowerCase().replaceAll(" ", "_");
		return `segment_${segmentName}`;
	};

	onSegmentChange = (segmentId: number) => {
		TrackAnalytics.trackOnAction({
			event: "Filter Applied",
			SelectionName: "segment",
			InitialValue: this.getSegmentAnalyticsValue(this.ladderFilter.selectedSegmentId),
			EndValue: this.getSegmentAnalyticsValue(segmentId),
			PageArea: "primary_controls",
			LeagueName: this.league?.name,
		});

		this._leaderboardsStore.setSelectedSegmentId(segmentId);
		this._leaderboardsStore.setSelectedTournamentId(0);

		void this._fetchLeaderboard();
	};

	onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;

		this._leaderboardsStore.setSearchValue(value);
		void this._fetchLeaderboard();
	};

	onClearSearch = () => {
		this._leaderboardsStore.setSearchValue("");
		void this._fetchLeaderboard();
	};

	onSortByStat = (stat: OrderBy) => {
		this._currentSortBy = stat;
		this._currentOrder = this._currentOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		void this._fetchLeaderboard();
	};

	showCompareModal = (userId: number) => {
		this._modalsStore.showModal(ModalType.COMPARE, {
			message: "",
			userId,
			leagueId: this._leagueId,
			segmentId: this.ladderFilter.selectedSegmentId
				? this.ladderFilter.selectedSegmentId
				: undefined,
			tournamentId: this.ladderFilter.selectedTournamentId
				? this.ladderFilter.selectedTournamentId
				: undefined,
		});
	};

	dispose() {
		this._leaderboardsStore.clearStore();
	}
}
