import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {TournamentStatus} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IExpertTeam, IJSONProvider} from "data/providers/json/json.provider";
import {compact, concat, findIndex, first, get, includes, last, some} from "lodash";
import {IRound} from "data/stores/rounds/rounds.store";
import type {
	ITournamentPlayersPayload,
	ITournamentPlayersProvider,
	IUsesPlayer,
} from "data/providers/api/tournament.provider";
import {
	getCompletedRoundStatuses,
	getPlayingRoundStatuses,
	getScheduledRoundStatuses,
} from "data/utils/helper";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface ITournament {
	id: number;
	name: string;
	status: TournamentStatus;
	image: string;
	rounds: IRound[];
	seasonSegmentId: number;
	startDate: string;
	endDate: string;
	currentRoundId: number | null;
	courses: ICourse[];
	canSkip: boolean;
	feedId: string;
}

export interface ICourse {
	id: number;
	name: string;
	abbreviation: string | null;
	country: string | null;
	city: string | null;
	state: string | null;
	isPrimary: boolean;
}

export interface ITournamentsStore {
	get list(): ITournament[];
	get expertTeams(): IExpertTeam[];
	get usesPlayersList(): IUsesPlayer[];
	get selectedTournament(): ITournament | null;
	get selectedTournamentId(): number;
	get selectedRoundIndex(): number;
	get scheduleTournaments(): ITournament[];
	get completedTournaments(): ITournament[];
	get leaderboardTournament(): ITournament | undefined;
	get currentTournament(): ITournament | undefined;
	get scoreTournament(): ITournament | null;
	get activeTournament(): ITournament | undefined;
	get availableTournamentsIds(): number[];
	get scheduleTournamentsIds(): number[];
	get isSelectedTournamentDisabledByStatus(): boolean;
	get isSelectedTournamentDisabled(): boolean;
	get isSelectedTournamentDisabledForPicks(): boolean;
	get isDisabledForSelectTeam(): boolean;
	get playingRound(): IRound | undefined;
	get currentRound(): IRound | undefined;
	get currentRoundIndex(): number;
	get isDisabledByCompleteRound(): boolean;
	get isDisabledChangeTeam(): boolean;
	get isSelectedTournamentScore(): boolean;
	get selectedTournamentRounds(): IRound[];
	get scoreRounds(): IRound[];
	get selectedRound(): IRound;

	getTournamentById: (id: number) => ITournament | undefined;
	setSelectedTournament: (tournament: ITournament | null) => void;
	setSelectedRoundIndex: (index: number) => void;
	fetchTournaments(): Promise<void>;
	fetchUsesPlayers(payload: ITournamentPlayersPayload): Promise<void>;
	fetchExpertTeams(payload: ITournamentPlayersPayload): Promise<void>;
}

@injectable()
export class TournamentsStore implements ITournamentsStore {
	@observable private _list: ITournament[] = [];
	@observable private _selectedTournament: ITournament | null = null;
	@observable private _selectedRoundIndex: number = 0;
	@observable private _usesPlayersList: IUsesPlayer[] = [];
	@observable private _expertTeams: IExpertTeam[] = [];
	get list() {
		return this._list;
	}

	get expertTeams() {
		return this._expertTeams;
	}

	get usesPlayersList() {
		return this._usesPlayersList;
	}

	get selectedTournament() {
		return this._selectedTournament;
	}

	get selectedRoundIndex() {
		return this._selectedRoundIndex;
	}

	get selectedTournamentId() {
		if (!this._selectedTournament) return 0;

		return this._selectedTournament.id;
	}

	get scheduleTournaments() {
		return this._list.filter((e) => e.status === TournamentStatus.Scheduled);
	}

	get completedTournaments() {
		return this._list.filter((e) => e.status === TournamentStatus.Completed);
	}

	get leaderboardTournament() {
		const lastCompleteTournament = last(this.completedTournaments);

		if (lastCompleteTournament && !lastCompleteTournament.canSkip) {
			return lastCompleteTournament;
		}

		return this.activeTournament;
	}

	get currentTournament() {
		// const isScheduledTournamentRoundStart = this.scheduleTournaments.length
		// 	? first(this.scheduleTournaments)?.rounds[0].status !== RoundStatusFeed.SCHEDULED
		// 	: false;
		// const isSeasonNotStart = eq(size(this.scheduleTournaments), size(this.list));

		const lastCompleteTournament = last(this.completedTournaments);

		if (lastCompleteTournament && !lastCompleteTournament.canSkip) {
			return lastCompleteTournament;
		}

		// if (some([isScheduledTournamentRoundStart, isSeasonNotStart])) {
		// 	return first(this.scheduleTournaments);
		// }

		return (
			this.activeTournament ||
			first(this.scheduleTournaments) ||
			lastCompleteTournament ||
			last(this.list)
		);
	}

	get scoreTournament() {
		return this.activeTournament || last(this.completedTournaments) || null;
	}

	get isSelectedTournamentScore() {
		return includes(
			[TournamentStatus.Playing, TournamentStatus.Completed],
			this._selectedTournament?.status
		);
	}

	get activeTournament() {
		return this.list.find((e) => e.status === TournamentStatus.Playing);
	}

	get availableTournamentsIds() {
		return compact(
			concat(
				// this.completedTournaments,
				this.activeTournament,
				first(this.scheduleTournaments)
			)
		).map(({id}) => id);
	}

	get availableTournamentsForPickIds() {
		return compact(concat(first(this.scheduleTournaments))).map(({id}) => id);
	}

	get scheduleTournamentsIds() {
		return this.scheduleTournaments.map(({id}) => id);
	}

	get isSelectedTournamentDisabledByStatus() {
		return includes(
			[TournamentStatus.Playing, TournamentStatus.Completed],
			this.selectedTournament?.status
		);
	}

	get isSelectedTournamentDisabled() {
		return !includes(this.availableTournamentsIds, this.selectedTournamentId);
	}

	get isSelectedTournamentDisabledForPicks() {
		return !includes(this.availableTournamentsForPickIds, this.selectedTournamentId);
	}

	get isDisabledByCompleteRound() {
		return includes(getCompletedRoundStatuses(), this.selectedRound?.status);
	}

	get isDisabledChangeTeam() {
		return includes(
			[...getCompletedRoundStatuses(), ...getPlayingRoundStatuses()],
			this.selectedRound?.status
		);
	}

	get isDisabledForSelectTeam() {
		return some([this.isSelectedTournamentDisabled, this.isDisabledChangeTeam]);
	}

	get selectedTournamentRounds(): IRound[] {
		return get(this._selectedTournament, "rounds", []);
	}

	get selectedRound(): IRound {
		return this.selectedTournamentRounds[this.selectedRoundIndex];
	}

	get playingRound() {
		return this.selectedTournamentRounds.find((round) =>
			includes(getPlayingRoundStatuses(), round.status)
		);
	}

	get scheduledRounds() {
		return this.selectedTournamentRounds.filter((round) =>
			includes(getScheduledRoundStatuses(), round.status)
		);
	}

	get completedRounds() {
		return this.selectedTournamentRounds.filter((round) =>
			includes(getCompletedRoundStatuses(), round.status)
		);
	}

	get scoreRounds() {
		return this.selectedTournamentRounds.filter((round) =>
			includes([...getPlayingRoundStatuses(), ...getCompletedRoundStatuses()], round.status)
		);
	}

	get currentRound() {
		return this.playingRound || first(this.scheduledRounds) || last(this.completedRounds);
	}

	get currentRoundIndex() {
		return findIndex(
			this.selectedTournamentRounds,
			(round) => round.id === this.currentRound?.id
		);
	}

	constructor(
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider,
		@inject(Bindings.TournamentPlayersProvider)
		private _tournamentProvider: ITournamentPlayersProvider
	) {
		makeAutoObservable(this);
	}

	getTournamentById = (id: number) => {
		return this._list.find((tournament) => tournament.id === id);
	};

	@action setSelectedTournament = (tournament: ITournament | null) => {
		this._selectedTournament = tournament;
	};

	@action setSelectedRoundIndex = (index: number) => {
		this._selectedRoundIndex = index === -1 ? 0 : index;
	};

	@action
	async fetchTournaments() {
		const {data} = await this._jsonProvider.tournaments();

		runInAction(() => {
			this._list = data;

			if (this.activeTournament) {
				TrackAnalytics.tournamentId = this.activeTournament.feedId;
			}
		});
	}

	@action async fetchUsesPlayers(payload: ITournamentPlayersPayload) {
		const {data} = await this._tournamentProvider.uses_players(payload);

		runInAction(() => {
			this._usesPlayersList = data.success.playersStats;
		});
	}

	@action
	async fetchExpertTeams(payload: ITournamentPlayersPayload) {
		const {data} = await this._jsonProvider.expertTeam(payload);

		runInAction(() => {
			this._expertTeams = data;
		});
	}
}
