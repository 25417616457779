import emptyPlayer from "assets/img/empty_player.png";
import {includes, isNull, isNumber, isUndefined} from "lodash";
import {RoundStatusFeed} from "data/enums";
import {ICourse} from "data/stores/tournaments/tournaments.store";

export const numberOrEmpty = (value: number | null | undefined) => value || "";

export const numberOrSlash = (value: number | string | null | undefined, defValue = "-") => {
	if (isNumber(value)) {
		return value;
	}

	return value || defValue;
};

export const numberWithPlus = (value: number | null | undefined) => {
	if (isNull(value) || isUndefined(value)) return "-";

	if (value > 0) return `+${value}`;

	return String(value);
};

export const getHolePtsValue = (value: number | null | undefined) => {
	if (isNull(value) || isUndefined(value)) return "-";

	if (value === 0) return "E";

	if (value > 0) return `+${value}`;

	return String(value);
};
export const numberOrZero = (value: number | null | undefined) => value || 0;

export const getPlayerHeadshot = (value: string | null) => {
	if (!value || includes(value, "default")) {
		return emptyPlayer;
	}

	return value;
};

export const percentValue = (value: number | null | undefined) => {
	return value ? `${value}%` : "-";
};

// export const onScrollToElement = (id: string) => {
// 	const el = document.getElementById(id);
//
// 	el && el.scrollIntoView({
// 		behavior: "smooth",
// 		block: "start",
// 	});
// }

export const getScheduledRoundStatuses = () => {
	return [
		RoundStatusFeed.GROUPINGS_OFFICIAL,
		RoundStatusFeed.NOT_STARTED,
		RoundStatusFeed.SCHEDULED,
	];
};

export const getPlayingRoundStatuses = () => {
	return [RoundStatusFeed.IN_PROGRESS /*, RoundStatusFeed.SUSPENDED*/];
};

export const getCompletedRoundStatuses = () => {
	return [RoundStatusFeed.OFFICIAL, RoundStatusFeed.PLAY_COMPLETE];
};

const createCourseFullName = (course: ICourse) => {
	let name = "";

	if (course.name) {
		name += course.name;
	}

	if (course.city) {
		name += `, ${course.city}`;
	}

	if (course.country) {
		name += `, ${course.country}`;
	}

	return name;
};

const createCourseShortName = ({abbreviation, city, country}: ICourse) => {
	let name = "";

	if (abbreviation) {
		name += `(${abbreviation})`;
	}

	if (city) {
		name += `, ${city}`;
	}

	if (country) {
		name += `, ${country}`;
	}

	return name;
};

export const getTournamentCoursesName = (courses: ICourse[]) => {
	const primaryCourse = courses.find(({isPrimary}) => isPrimary);

	if (courses.length === 1 || primaryCourse) {
		return createCourseFullName(primaryCourse || courses[0]);
	}

	return courses?.map(createCourseShortName).join("; ") || ""; // courses may be undefined if the json is old
};

export const resizeWidget = () => {
	const widget = document.getElementById("widget-container");

	if (widget) {
		const height = widget.scrollHeight;

		window.parent.postMessage(
			{
				type: "resize",
				height,
			},
			"*"
		);
	}
};
