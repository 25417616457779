import {Cookie} from "./cookie";

export interface ITrackOnLoad {
	PageName?: string;
	PageSection?: string;
	LeagueName?: string;
}
export interface ITrackOnAction {
	event: string;
	PageName?: string;
	PageSection?: string;
	PageArea?: string;
	SelectionName?: string;
	Segment?: string;
	PlayerId?: string[];
	CaptainPlayerId?: string[];
	RosterId?: number[];
	LeagueName?: string;
	LeagueStatus?: string;
	InitialValue?: string | number;
	EndValue?: string | number;
}

const PAGE_NAME_MAP = new Map([["league", "leagues"]]);

export const getPageName = () => {
	const path = window.location.pathname.split("/").filter(Boolean);
	const pageName = path.length > 0 ? path[0].replaceAll("-", "_") : "home";
	return PAGE_NAME_MAP.get(pageName) || pageName;
};

const PAGE_SECTION_MAP = new Map([
	["leagues", "my_leagues"],
	["create", "create_league"],
	["ladder", "standings"],
]);

export const getPageSection = () => {
	const path = window.location.pathname.split("/").filter(Boolean);
	const pageSection = path.length > 0 ? path[path.length - 1].replaceAll("-", "_") : "home";
	return PAGE_SECTION_MAP.get(pageSection) || pageSection;
};

const cookie = new Cookie();
export const getWebview = () => {
	return cookie.isAppSSO() ? "yes" : "no";
};

export abstract class TrackAnalytics {
	private static _tournamentId: string | null = null;

	static get tournamentId() {
		return this._tournamentId;
	}

	static set tournamentId(value: string | null) {
		this._tournamentId = value;
	}

	public static trackOnLoad(params: ITrackOnLoad = {}) {
		const userId = window.amplitude?.getUserId();
		const properties = {
			device_id: window.amplitude?.getDeviceId(),
			event_type: "Pageload",
			event_properties: {
				PageName: getPageName(),
				PageSection: getPageSection(),
				Webview: getWebview(),
				TournamentId: this.tournamentId,
				...params,
			},
		};
		console.log("trackOnLoad---", userId, properties);
		try {
			window.amplitude?.track(properties);
		} catch (e) {
			console.log(e);
		}
	}

	public static trackOnAction(params: ITrackOnAction) {
		const {event, ...rest} = params;
		const userId = window.amplitude?.getUserId();
		const properties = {
			device_id: window.amplitude?.getDeviceId(),
			event_type: event,
			event_properties: {
				PageName: getPageName(),
				PageSection: getPageSection(),
				Webview: getWebview(),
				TournamentId: this.tournamentId,
				...rest,
			},
		};
		console.log("trackOnAction---", userId, properties);
		try {
			window.amplitude?.track(properties);
		} catch (e) {
			console.log(e);
		}
	}
}
