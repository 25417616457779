import {Cookies} from "react-cookie";
import {every, isNull, isString} from "lodash";

interface IPgaSession {
	gigyaUID: string;
	device: string;
}

export class Cookie {
	private _cookies = new Cookies();
	private _pga_session = this._cookies.get<IPgaSession>("pga_session");
	private _pga_hash = this._cookies.get<string>("pga_hash");

	public isAppSSO() {
		return every([this._pga_session, this._pga_hash]);
	}

	public isNeedAuthToApp() {
		return isNull(JSON.parse(JSON.stringify(this._pga_session?.gigyaUID)));
	}

	public setCookie(name: string, value: string | null) {
		this._cookies.set(name, value);
	}

	public getCookie(name: string) {
		return this._cookies.get(name) as string;
	}

	public pgaAppPayload() {
		return {
			pgaSession: isString(this._pga_session)
				? this._pga_session
				: JSON.stringify(this._pga_session),
			pgaHash: this._pga_hash,
		};
	}

	public setUserId() {
		if (!this._pga_session?.gigyaUID) return;
		console.log("setUserId---", this._pga_session.gigyaUID);
		window.amplitude?.setUserId(this._pga_session.gigyaUID);
	}
}
