import {makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GigyaScreenType, RequestState} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILeaderboardsStore} from "data/stores/leaderboards/leaderboards.store";
import type {ITournamentsStore, ITournament} from "data/stores/tournaments/tournaments.store";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {IUserStore} from "data/stores/user/user.store";
import {SSO} from "data/utils/gigya_sso";
import {useNavigate} from "react-router-dom";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {resizeWidget} from "data/utils/helper";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IWidgetController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isAuthorized(): boolean;
	get selectedTournament(): ITournament | null;
}

@injectable()
export class WidgetController implements IWidgetController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _navigate!: ReturnType<typeof useNavigate>;
	private _disposerReaction!: ReturnType<typeof reaction>;
	_sso = new SSO();

	get isAuthorized() {
		return this._userStore.isAuthorized;
	}

	get selectedTournament() {
		return this._tournamentsStore.selectedTournament;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardStore: ILeaderboardsStore
	) {
		makeAutoObservable(this);
	}

	private onJoinLeague = () => {
		const joinCode = localStorage.getItem("join_code") || null;

		if (joinCode) {
			this._navigate(`/leagues/join/${joinCode}`, {replace: true});
		}
	};

	dispose() {
		this._disposerReaction?.();
	}

	init({navigate}: IProps) {
		this._navigate = navigate;
		this._requestState = RequestState.PENDING;

		const joinCode = localStorage.getItem("join_code") || null;

		this._tournamentsStore.setSelectedTournament(
			this._tournamentsStore.currentTournament ?? null
		);

		const tournamentId = this._tournamentsStore.selectedTournamentId;

		void Promise.all([
			this._poolPlayersStore.fetchPoolPlayers({tournamentId}),
			this._tournamentsStore.fetchUsesPlayers({tournamentId}),
			this._tournamentsStore.fetchExpertTeams({tournamentId}),
		])
			.then(() => {
				if (!this._tournamentsStore.isSelectedTournamentDisabled) {
					void this._leaderboardStore.fetchPlayersRanking({tournamentId});
				}
			})
			.then(() => (this._requestState = RequestState.SUCCESS))
			.then(() => resizeWidget());

		this._disposerReaction = reaction(
			() => this._userStore.isAuthorized,
			(isAuth) => {
				if (isAuth) {
					void this._teamStore
						.fetchTeam({tournamentId})
						.then(() =>
							this._leaguesStore.fetchMyLeagues({
								limit: 3,
								orderBy: "numTeams",
							})
						)
						.then(() => resizeWidget())
						.then(() => this.onJoinLeague());
				} else {
					if (joinCode) {
						this._sso.showScreenSet(GigyaScreenType.Login);
					}
				}
			},
			{fireImmediately: true}
		);
	}
}
