import React from "react";
import styled from "@emotion/styled";
import {Box, Collapse} from "@mui/material";
import {observer} from "mobx-react";
import {getPlayerHeadshot} from "data/utils/helper";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {BonusType} from "data/enums";
import {ILineUp} from "views/components/modals/modal_compare/tournament/tournament.controller";
import {Exist} from "views/components/exist/exist.component";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ILineupRowController} from "./lineup_row.controller";

const Wrapper = styled.div<{isOpen: boolean; isBench: boolean}>`
	background: ${({isOpen, isBench}) => (isOpen && !isBench ? "#F2F2F2" : "transparent")};
	border-radius: 4px;
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 1fr min-content 1fr;
`;

const LineupRowPlayer = styled.div`
	display: grid;
	grid-template-columns: min-content 1fr;
	font-size: 12px;
	padding: 2px 4px 2px 2px;

	&.reverse {
		grid-template-columns: 1fr min-content;
		padding: 2px 2px 2px 4px;
	}

	@media (min-width: 768px) {
		grid-template-columns: min-content 1fr;
		padding: 10px 8px;

		&.reverse {
			padding: 10px 8px;
			grid-template-columns: 1fr min-content;
		}
	}
`;

const LineupRowPlayerStats = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 30px);
	grid-column-gap: 1px;
	line-height: 165%;

	@media (min-width: 768px) {
		grid-template-columns: repeat(3, 41px);
		grid-column-gap: 10px;
		color: #757575;
		font-weight: 600;
	}
`;

const PlayerImage = styled(Box)`
	width: 37px;
	min-width: 37px;
	height: 37px;
	cursor: pointer;
	position: relative;

	img {
		background: #d6d4d2;
		display: block;
		width: 100%;
		border-radius: 50%;
		overflow: hidden;
	}
`;

const BonusesRow = styled.div`
	display: grid;
	grid-template-columns: 43px 43px 1fr 43px 43px;
	gap: 8px;
	font-size: 12px;
	font-weight: 600;
	line-height: 135%;
	padding: 8px 0;
	color: #2b2b2b;

	&.head {
		border-bottom: 1px solid #000;
	}

	@media (min-width: 768px) {
		padding: 10px 0;
	}
`;
const PlayerNameRow = styled(Box)`
	display: flex;
`;
const PlayerName = styled(Box)`
	display: flex;
	flex: 1;
	font-size: 12px;
	padding: 0 20px;
`;

const CapIcon = styled(Box)`
	display: flex;
	width: 18px;
	height: 18px;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: #fff;
	background: #004c95;
	font-family: var(--fontFamilyBase);
	font-size: 12px;
	position: absolute;
	top: -4px;
	left: -4px;
	z-index: 9;
`;

const bonusesData = {
	[BonusType.HoleDoubleEagle]: {
		key: "modal.compare.double_eagle",
		defVal: "DOUBLE EAGLE",
	},
	[BonusType.HoleEagle]: {
		key: "modal.compare.eagle",
		defVal: "EAGLE",
	},
	[BonusType.HoleBirdie]: {
		key: "modal.compare.birdie",
		defVal: "BIRDIE",
	},
	[BonusType.HolePar]: {
		key: "modal.compare.par",
		defVal: "PAR",
	},
	[BonusType.HoleBogey]: {
		key: "modal.compare.bogey",
		defVal: "BOGEY",
	},
	[BonusType.HoleDoubleBogey]: {
		key: "modal.compare.double_bogey",
		defVal: "DOUBLE BOGEY",
	},
	[BonusType.HoleRoundBonus]: {
		key: "modal.compare.round_bonus",
		defVal: "END OF ROUND",
	},
	[BonusType.HoleFedex]: {
		key: "modal.compare.fedex_bonus",
		defVal: "FEDEX",
	},
};

interface ILineupRow extends ILineUp {
	isBench?: boolean;
	getValue: <T>(value: T) => T | string;
}

export const LineupRow: React.FC<ILineupRow> = observer(
	({playerB, playerA, bonuses, getValue, isBench = false}) => {
		const {i18n, isOpen, toggleHandler} = useViewController<ILineupRowController>(
			Bindings.LineupRowController
		);

		return (
			<Wrapper isOpen={isOpen} isBench={isBench}>
				<Container>
					<LineupRowPlayer>
						<div className="flex flex-col items-end md:justify-center">
							<LineupRowPlayerStats>
								<div>{getValue(playerA?.feedPosition)}</div>
								<div>{getValue(playerA?.tournamentPts)}</div>
								<div>{getValue(playerA?.roundPts)}</div>
							</LineupRowPlayerStats>

							{/*<Exist when={isMobile}>*/}
							{/*	<div className="font-semibold mr-0.5 truncate max-w-[90px]">*/}
							{/*		{playerA?.name}*/}
							{/*	</div>*/}
							{/*</Exist>*/}
						</div>

						<div className="flex">
							<div className="flex items-center gap-1 justify-end min-[400px]:ml-4 md:min-w-[120px] md:ml-6">
								{/*<Exist when={!isMobile}>*/}
								{/*	<div className="font-semibold">{playerA?.name}</div>*/}
								{/*</Exist>*/}
								<PlayerImage>
									<Exist when={playerA?.isCaptain}>
										<CapIcon>C</CapIcon>
									</Exist>
									<img
										src={getPlayerHeadshot(playerA?.headshot ?? null)}
										alt="player icon"
									/>
								</PlayerImage>
							</div>
						</div>
					</LineupRowPlayer>

					<div className="flex items-center">
						<IconButton
							onClick={toggleHandler}
							className={`p-0 transition-all transform ${isOpen ? "rotate-180" : ""}`}>
							<KeyboardArrowDownIcon />
						</IconButton>
					</div>

					<LineupRowPlayer className="reverse">
						<div className="flex justify-end">
							<div className="flex items-center gap-1 justify-start min-[400px]:mr-4 md:min-w-[120px] md:mr-6">
								<PlayerImage>
									<Exist when={playerB?.isCaptain}>
										<CapIcon>C</CapIcon>
									</Exist>
									<img
										src={getPlayerHeadshot(playerB?.headshot ?? null)}
										alt="player icon"
									/>
								</PlayerImage>

								{/*<Exist when={!isMobile}>*/}
								{/*	<div className="font-semibold">{playerB?.name}</div>*/}
								{/*</Exist>*/}
							</div>
						</div>

						<div className="flex flex-col items-start md:justify-center">
							<LineupRowPlayerStats>
								<div>{getValue(playerB?.feedPosition)}</div>
								<div>{getValue(playerB?.tournamentPts)}</div>
								<div>{getValue(playerB?.roundPts)}</div>
							</LineupRowPlayerStats>

							{/*<Exist when={isMobile}>*/}
							{/*	<div className="font-semibold ml-0.5 truncate max-w-[90px]">*/}
							{/*		{playerB?.name}*/}
							{/*	</div>*/}
							{/*</Exist>*/}
						</div>
					</LineupRowPlayer>
				</Container>

				<Collapse in={isOpen}>
					<PlayerNameRow>
						<PlayerName
							sx={{justifyContent: "flex-end"}}
							className="font-semibold ml-0.5 truncate">
							{playerA?.name}
						</PlayerName>
						<PlayerName
							sx={{justifyContent: "flex-start"}}
							className="font-semibold ml-0.5 truncate">
							{playerB?.name}
						</PlayerName>
					</PlayerNameRow>
					<BonusesRow className="head">
						<div>{i18n.t("modal.compare.pts", "PTS")}</div>
						<div>{i18n.t("modal.compare.shot", "SHOT")}</div>
						<div />
						<div>{i18n.t("modal.compare.shot", "SHOT")}</div>
						<div>{i18n.t("modal.compare.pts", "PTS")}</div>
					</BonusesRow>

					{bonuses.map(({bonus, playerA, playerB}) => {
						const data = bonusesData[bonus];
						return (
							<BonusesRow key={bonus}>
								<div>{getValue(playerA?.pts)}</div>
								<div>{getValue(playerA?.count)}</div>
								<div>{i18n.t(data?.key, data?.defVal)}</div>
								<div>{getValue(playerB?.count)}</div>
								<div>{getValue(playerB?.pts)}</div>
							</BonusesRow>
						);
					})}
				</Collapse>
			</Wrapper>
		);
	}
);
