import {makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {type ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {LeagueType} from "data/enums";
import type {IUserStore} from "data/stores/user/user.store";
import {TrackAnalytics} from "data/utils/analytic_service";
import {SITE_URL} from "data/constants";

interface IController {
	league: ILeague;
}
export interface ILeagueCardController extends ViewController<IController> {
	readonly i18n: ILocalizationStore;

	get isOwner(): boolean;
	get isManagerShow(): boolean;
	get leagueLink(): string;
	get frameViewLeagueLink(): string;

	setFavorite: () => void;
	unsetFavorite: () => void;
	deleteLeague: () => void;
}

@injectable()
export class LeagueCardController implements ILeagueCardController {
	@observable _league: ILeague | undefined = undefined;
	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get isOwner() {
		return this._league?.leagueManager?.userId === this._userStore.user?.id;
	}

	get isManagerShow() {
		return Boolean(
			this._league?.class === LeagueType.REGULAR && this._league.leagueManager?.displayName
		);
	}

	get leagueLink() {
		if (!this._league?.id) {
			return "/leagues";
		}
		// const preSeasonLink =
		// 	this._userStore.user?.id === this._league.leagueManager?.userId
		// 		? `/league/${this._league.id}/settings`
		// 		: `/league/${this._league.id}/about`;
		// return this._league?.status === LeagueStatus.SCHEDULED
		// 	? preSeasonLink
		// 	: `/league/${this._league.id}/ladder`;

		return `/league/${this._league.id}/ladder`;
	}

	get frameViewLeagueLink() {
		if (!this._league?.id) {
			return `${SITE_URL}leagues`;
		}

		return `${SITE_URL}league/${this._league.id}/ladder`;
	}

	onTrackAction = () => {
		TrackAnalytics.trackOnAction({
			event: "Favorited League",
			LeagueName: this._league?.name,
			LeagueStatus: this._league?.privacy,
		});
	};

	setFavorite = () => {
		if (this._league?.id) {
			this.onTrackAction();
			void this._leaguesStore.setFavorite({leagueId: this._league.id});
		}
	};

	unsetFavorite = () => {
		if (this._league?.id) {
			void this._leaguesStore.unsetFavorite({leagueId: this._league.id});
		}
	};

	deleteLeague = () => {
		if (this._league?.id) {
			void this._leaguesStore.deleteLeague({leagueId: this._league.id});
		}
	};

	init(param: IController): void {
		this._league = param.league;
	}
}
