import React from "react";
import {observer} from "mobx-react";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {type IFooterController} from "views/components/footer/footer.controller";
import {NavLink} from "react-router-dom";
import logoIcon from "assets/img/logo.png";
import {ReactComponent as GeniusLogo} from "assets/img/genius _logo.svg";
import styled from "@emotion/styled";
import {Box, Container} from "@mui/material";
import privacyLogo from "assets/img/icons/privacyoptions.png";

const FooterWrapper = styled(Box)`
	background: #ffffff;
	width: 100%;
	border-top: 1px solid #d6d4d2;
`;

const FooterContent = styled(Container)`
	gap: 12px;
	min-height: 50px;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;

	.button-icon {
		color: #000;
		padding: 0;
	}
`;
const Row = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 12px;
		justify-content: center;
	}

	.copyright {
		@media (max-width: 768px) {
			order: 2;
		}
	}

	&.cookie {
		justify-content: flex-end;
	}
`;
const LinksBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;
	text-align: center;

	a {
		display: inline-flex;
		font-weight: bold;

		&.privacy {
			img {
				display: block;
				width: 28px;
			}
		}

		&:hover {
			text-decoration: underline;
		}
	}

	span {
		margin: 0 4px;
	}

	@media (max-width: 768px) {
		align-items: center;
	}
`;
const GBlock = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
	}

	@media (max-width: 768px) {
		order: 1;
	}
`;

export const Footer: React.FC = observer(() => {
	const {i18n, isApp} = useViewController<IFooterController>(Bindings.FooterController);

	if (isApp) {
		return null;
	}

	return (
		<FooterWrapper>
			<FooterContent>
				<Row>
					<NavLink to={"/"}>
						<img className="h-16 w-auto" src={logoIcon} alt="Fantasy golf" />
					</NavLink>
					<LinksBlock>
						<div>
							<a
								href="https://www.pgatour.com/company/privacy-policy"
								target="_blank"
								rel="noreferrer">
								{i18n.t("footer.links.privacy", "Privacy Policy")}
							</a>
							<span>|</span>
							<a
								href="https://www.pgatour.com/company/tos"
								target="_blank"
								rel="noreferrer">
								{i18n.t("footer.links.terms", "Terms of Use")}
							</a>
						</div>
						<div>
							<a
								href="https://www.pgatour.com/company/accessibility-statement"
								target="_blank"
								rel="noreferrer">
								{i18n.t("footer.links.accessibility", "Accessibility Statement")}
							</a>
							<span>|</span>
							<a href="help/contact-us" target="_blank" rel="noreferrer">
								{i18n.t("footer.links.contact_us", "Contact Us")}
							</a>
						</div>
						<div>
							<a
								href="https://www.pgatour.com/company/do-not-sell-or-share-my-personal-information"
								className="flex gap-1 privacy"
								target="_blank"
								rel="noreferrer">
								<img src={privacyLogo} alt="privacy logo" />
								{i18n.t("footer.links.privacy_choice", "Your Privacy Choices")}
							</a>
						</div>
					</LinksBlock>
				</Row>

				<Row className="info">
					<p className="copyright">
						{i18n.t("footer.copyright", "© 2024 Genius Sports")}
					</p>
					<GBlock>
						<a
							href="https://www.geniussports.com/engage/"
							target="_blank"
							rel="noreferrer">
							{i18n.t("footer.powered", "Powered by:")} <GeniusLogo />
						</a>
					</GBlock>
				</Row>
				<Row className="cookie">
					{/*OneTrust Cookies Settings button start*/}
					<button id="ot-sdk-btn" className="ot-sdk-show-settings">
						Cookie Settings
					</button>
					{/*OneTrust Cookies Settings button end*/}
				</Row>
			</FooterContent>
		</FooterWrapper>
	);
});
